import React from 'react'
export default function Login() {
    return (
        <div>
          
            <section class="contact-form-wrap section">
                <div class="container login" >
                    <h1 className='align'>page is under process........... </h1>
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12">
                            {/* <form id="contact-form" class="contact__form" method="post" action="index.html">
                                <h3 class="text-md mb-4 log">Log in</h3>
                                <div class="form-group">
                                    <input name="email" type="email" class="form-control" placeholder="email" required />
                                </div>
                                <div class="form-group">
                                    <input name="password" type="password" class="form-control" placeholder="password" required />
                                </div>
                              
                                <button class="btn btn-main" name="submit" type="submit">Login</button>
                            </form> */}
                            
                        </div>
                    </div>
                    </div>
            </section>
        </div>
    )
}
